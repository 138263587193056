import { Component, StrictMode } from "react";
import { BusinessService } from "../services/customer";
import css from "../style/customerHome.module.css";
import { Const } from "../globel";
import { ScratchCardList } from "../widget/scratchCard";
import { Offer, OffersList } from "../widget/offers";

export default class CustomerHomeScereen extends Component {
  constructor(props: any) {
    super(props);
    this.state = {
      customer: {},
      myStores: [],
      brands: [],
      error: "",
      selectedOffer: null,
    };
  }

  _setRealTimeResponse = async () => {
    let businessService = new BusinessService();
    let { myStores }: any = this.state;
    await businessService
      .realTimeRes()
      .then((res) => {
        this._setRealTimeResponse();
        if (res.data.value === "points") {
          for (let i = 0; i < myStores.length; i++) {
            if (myStores[i].businessId === res.data.businessId) {
              myStores[i].currentPoints = res.data.currentPoints;
              this.setState({ myStores });
              return;
            }
          }
          this._loadMyStores();
        } else if (res.data.value === "scratchCard") {
          console.log(res.data);
        }
      })
      .catch((e) =>
        setTimeout(() => {
          // this._setRealTimeResponse();
        }, 10000)
      );
  };

  _loadMyStores = () => {
    let businessService = new BusinessService();
    businessService
      .getMyStores()
      .then((res) => this.setState({ myStores: res.data }))
      .catch((e) => console.log(e));
  };

  loaded = false;
  async componentDidMount(): Promise<void> {
    if (this.loaded) return;
    this.loaded = true;
    navigator.geolocation.getCurrentPosition(
      (position) => {
        const { latitude, longitude } = position.coords;
        console.log(latitude, longitude);
      },
      (err) => console.log(err.message)
    );
    let businessService = new BusinessService();
    businessService
      .getAbout()
      .then((res) => this.setState({ customer: res.data }))
      .catch((e) => console.log(e));
    this._loadMyStores();
    businessService
      .getAllBrands()
      .then((res) => this.setState({ brands: res.data }))
      .catch((e) => console.log(e));
    this._setRealTimeResponse();
  }

  render() {
    const { brands, myStores, selectedOffer }: any = this.state;
    const { customer }: any = this.state;
    return (
      <StrictMode>
        <div className={css.homeBody}>
          <img
            style={{ width: "100%" }}
            alt="Ybes Cover"
            src="https://app.ybes.app/utils/media/default/customerCover.png"
          />
          {/* ///////////////////////////////////////////////////////////// */}
          {/* ///////////////////////////////////////////////////////////// */}
          <img
            className={css.cover}
            alt="Ybes Cover"
            src="https://app.ybes.app/utils/media/gallery/_2024-05-11T16:33:32.540Z.png"
          />
          {/* ///////////////////////////////////////////////////////////// */}
          {/* ///////////////////////////////////////////////////////////// */}
          <div className={css.titleRow}>
            Your Stores
            <div className={css.viewAll} />
          </div>
          <div className={css.itemRow}>
            {myStores.map((it: any, k: number) => (
              <div className={css.eachStores} key={k}>
                <div className={css.eachStore_a}>
                  <div className={css.eachStore_aA}>
                    <img
                      className={css.eachStore_aAa}
                      src={Const.imgUrl + it.pointIcon}
                      alt="Point"
                    />
                    <div className={css.eachStore_aAb}>{it.currentPoints}</div>
                  </div>
                  <div className={css.eachStore_aB}>{it.businessName}</div>
                  <div className={css.eachStore_aC}>Cafe Store</div>
                </div>
                {it.businessLogo === "" ? (
                  <img
                    className={css.eachStore_b}
                    src={Const.imgUrl + "default/hintHeader.png"}
                    alt={it.businessName}
                  />
                ) : (
                  <img
                    className={css.eachStore_b}
                    src={Const.imgUrl + it.businessLogo}
                    alt={it.businessName}
                  />
                )}
              </div>
            ))}
          </div>
          {/* ///////////////////////////////////////////////////////////// */}
          {/* ///////////////////////////////////////////////////////////// */}
          <div className={css.titleRow}>
            Offers
            <div className={css.viewAll} />
          </div>
          <OffersList
            onSelect={(v: any) => this.setState({ selectedOffer: v })}
          />
          {/* ///////////////////////////////////////////////////////////// */}
          {/* ///////////////////////////////////////////////////////////// */}
          <div
            className={css.titleRow}
            onClick={() => (window.location.pathname = "customer/scratchCard")}
          >
            Scratch & Win
            <div className={css.viewAll} />
          </div>
          <ScratchCardList />
          {/* ///////////////////////////////////////////////////////////// */}
          {/* ///////////////////////////////////////////////////////////// */}
          <div className={css.titleRow}>All Stores</div>
          <div className={css.itemRow}>
            {brands.map((it: any, k: number) =>
              it.picture === "" ? (
                <div className={css.eachBrand} key={k}>
                  {it.businessName}
                </div>
              ) : (
                <img
                  className={css.eachBrand}
                  src={Const.imgUrl + it.picture}
                  key={k}
                  alt={it.businessName}
                />
              )
            )}
          </div>
          <div className={css.footer} />
        </div>
        <div className={css.userDetails}>
          {/* <img
            className={css.userDetails_A}
            src="https://app.ybes.app/utils/media/default/customer_cover.png"
          /> */}
          <div className={css.userDetails_A} />
          <div className={css.userDetails_B}>
            <div className={css.userDetails_Ba}>Welcome back</div>
            <div className={css.userDetails_Bb}>
              {customer.name === "" && "User"}!
            </div>
          </div>
          <div className={css.userDetails_C} />
        </div>
        {selectedOffer !== null && (
          <Offer
            it={selectedOffer}
            onClick={() => this.setState({ selectedOffer: null })}
          />
        )}
      </StrictMode>
    );
  }
}

/* <VirtualHost *:80>
    ServerAdmin webmaster@ybes.live
    DocumentRoot /var/www/html/ybes.live
    ServerName ybes.live
    ServerAlias www.ybes.live

    ErrorLog /var/log/apache2/ybes.live_error.log
    CustomLog /var/log/apache2/ybes.live_access.log combined

    <Directory /var/www/html/ybes.live>
        Options Indexes FollowSymLinks
        AllowOverride All
        Require all granted
    </Directory>
</VirtualHost> */
