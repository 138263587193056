import { Component } from "react";
import PublicHomeScereen from "./screen/publicHome";
import CustomerLoginScreen from "./screen/customerLogin";
import CustomerHomeScereen from "./screen/customerHome";
import CustomerScratchCardScreen from "./screen/customerScratchCard";
import ShopScreen from "./screen/shop";
import { Const } from "./globel";

export default class App extends Component {
  constructor(props: any) {
    super(props);
    this.state = { isInitated: false };
  }

  _getCustomerScreen = () => {
    const path = window.location.pathname.split("/")[2];
    switch (path) {
      case "login":
        return <CustomerLoginScreen />;
      case "scratchCard":
        return <CustomerScratchCardScreen />;
      default:
        return <CustomerHomeScereen />;
    }
  };

  _getSereen = () => {
    const path = window.location.pathname.split("/")[1];
    switch (path) {
      case "customer":
        return (
          <div className="screenFrameBody">
            <div className="screenFrame">{this._getCustomerScreen()}</div>;
          </div>
        );
      case "shop":
        return <ShopScreen />;
      default:
        return (
          <div className="screenFrameBody">
            <PublicHomeScereen />;
          </div>
        );
    }
  };

  componentDidMount(): void {
    if (window.location.hostname === "localhost")
      Const.server = "http://localhost:8012/";
    this.setState({ isInitated: true });
  }

  render() {
    const { isInitated }: any = this.state;
    if (isInitated) return this._getSereen();
    else return "Iniating..";
  }
}

// git add . && git commit -m OP && git push
