import axios from "axios";
import { CSSProperties, Component } from "react";
import { Const } from "../globel";
import { CustomerOfferViewer } from "../widget/viewer";

class BillProdect {
  name: string = "";
  qty: number = 0;
  price: number = 0;
}

export default class ShopScreen extends Component {
  constructor(props: any) {
    super(props);
    this.state = {
      showRewards: false,
      items: [new BillProdect()],
      customerRewards: null,
      campaign: null,
      scratchCard: null,
      promotion: null,
    };
  }

  token = window.localStorage.getItem("shopToken") ?? "";
  phone = window.localStorage.getItem("lastPhone") ?? "";
  name = "";
  email = "";
  claimed = "";
  rewardClaimed = "";

  _onConfirm = async (e: any) => {
    e.preventDefault();
    let total = 0;
    if (this.phone.split("-").length !== 2) alert("Wrong phone number format");
    const { items, campaign, scratchCard, promotion }: any = this.state;
    items.forEach((it: BillProdect) => (total += it.qty * it.price));
    if (total < 1) alert("Wrong amount");
    let body: any = {
      name: this.name,
      email: this.email,
      phone: this.phone,
      amount: total,
      branchId: 10,
      salesId: "10",
      history: [],
      claimed: Number(this.claimed),
      rewardClaimedAmount: Number(this.rewardClaimed),
    };

    if (campaign) body.campaign = campaign.id;
    if (scratchCard) body.scratchCard = scratchCard.id;
    if (promotion) body.promotion = promotion.id;
    if (this.claimed !== "") body.claimed = Number(this.claimed);

    await axios
      .post(Const.server + "shop/purchase", body, {
        headers: { Authorization: this.token },
      })
      .then(() => {
        // alert("Succes");
      })
      .catch((e) => {
        console.log(e);
        alert("Error on updating");
      });
  };

  inputCss: CSSProperties = {
    border: "none",
    backgroundColor: "white",
    borderRadius: "0",
    margin: "2px",
    padding: "8px",
    fontSize: "16px",
  };
  titleCss: CSSProperties = {
    padding: "12px 2px",
    fontWeight: "600",
  };
  submitBtn: CSSProperties = {
    width: 200,
    padding: "8px",
    border: "none",
  };
  rewardBtnCss: CSSProperties = {
    padding: "8px",
    background: "#9B59B6",
    color: "white",
    cursor: "pointer",
  };

  _onClickReward = async () => {
    if (this.phone.split("-").length !== 2) {
      alert("Wrong phone number format");
    } else {
      this.setState({ showRewards: true, customerRewards: null });
      axios
        .get(Const.server + "shop/customer", {
          params: { phone: this.phone },
          headers: { Authorization: this.token },
        })
        .then((res) => this.setState({ customerRewards: res.data }))
        .catch((e) => {});
    }
  };

  render() {
    const { items, showRewards, customerRewards }: any = this.state;
    const { campaign, scratchCard, promotion }: any = this.state;
    let total = 0;
    items.forEach((it: BillProdect) => (total += it.qty * it.price));
    return (
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          height: "100vh",
        }}
      >
        <div onClick={() => alert(window.innerWidth)}>CLick</div>
        <input
          style={{ width: "400px", padding: 12 }}
          placeholder="Token"
          onChange={(e) => {
            this.token = e.target.value;
            window.localStorage.setItem("shopToken", this.token);
          }}
          defaultValue={this.token}
        />
        <form
          onSubmit={this._onConfirm}
          style={{ backgroundColor: "#95A5A6", padding: "12px" }}
          onChange={() => {
            if (
              items[items.length - 1].name !== "" &&
              items[items.length - 1].qty !== 0 &&
              items[items.length - 1].price !== 0
            ) {
              items.push(new BillProdect());
            }
            this.setState({});
          }}
        >
          <div style={{ display: "flex", paddingBottom: 10 }}>
            <input
              style={{ ...this.inputCss, width: 240 }}
              placeholder="Customer Name"
              onChange={(e) => (this.name = e.target.value)}
            />
            <input
              style={{ ...this.inputCss, width: 240 }}
              placeholder="Customer Email"
              onChange={(e) => (this.email = e.target.value)}
            />
          </div>
          <div style={{ display: "flex" }}>
            <input
              style={{ ...this.inputCss, width: 300 }}
              placeholder="Customer phone 91-9544013463"
              onChange={(e) => {
                this.phone = e.target.value;
                window.localStorage.setItem("lastPhone", this.phone);
              }}
              defaultValue={this.phone}
            />
            <div style={this.rewardBtnCss} onClick={this._onClickReward}>
              Ybes Reward
            </div>
          </div>
          <div style={{ display: "flex", padding: "10px 0" }}>
            <input
              style={{ ...this.inputCss, width: 140 }}
              placeholder="Campaign ID"
              value={campaign?.couponCode ?? "No Code"}
              disabled
            />
            <input
              style={{ ...this.inputCss, width: 140 }}
              placeholder="Scratch Card ID"
              value={scratchCard?.couponCode ?? "No Code"}
              disabled
            />
            <input
              style={{ ...this.inputCss, width: 140 }}
              placeholder="Promotion ID"
              value={promotion?.couponCode ?? "No Code"}
              disabled
            />
          </div>

          <div style={{ display: "flex" }}>
            <input
              style={{ ...this.inputCss, width: 140 }}
              placeholder="Climing amount"
              type="number"
              onChange={(e) => (this.claimed = e.target.value)}
              value={this.claimed}
            />
            <input
              style={{ ...this.inputCss, width: 140 }}
              placeholder="Reward amount"
              type="number"
              onChange={(e) => (this.rewardClaimed = e.target.value)}
              value={this.rewardClaimed}
            />
          </div>
          <div style={{ display: "flex" }}>
            <div style={{ ...this.titleCss, width: 200 }}>Item Name</div>
            <div style={{ ...this.titleCss, width: 100 }}>Qty</div>
            <div style={{ ...this.titleCss, width: 100 }}>Price</div>
            <div style={{ ...this.titleCss, width: 80, textAlign: "right" }}>
              Total
            </div>
          </div>
          {items.map((it: BillProdect, k: number) => (
            <div style={{ display: "flex", alignItems: "center" }} key={k}>
              <input
                placeholder="Item Nmae"
                style={{ ...this.inputCss, width: 200 }}
                onChange={(e) => (it.name = e.target.value)}
              />
              <input
                placeholder="00"
                style={{ ...this.inputCss, width: 100 }}
                type="number"
                onChange={(e) => (it.qty = Number(e.target.value))}
              />
              <input
                placeholder="00.0"
                style={{ ...this.inputCss, width: 100 }}
                type="number"
                onChange={(e) => (it.price = Number(e.target.value))}
              />
              <div style={{ ...this.titleCss, width: 80, textAlign: "right" }}>
                {it.qty * it.price}
              </div>
            </div>
          ))}
          <div
            style={{ textAlign: "right", padding: "10px 0", fontSize: "18px" }}
          >
            <b>{total}</b>
          </div>
          <button type="submit" style={this.submitBtn}>
            Confirm
          </button>
        </form>
        {showRewards && (
          <CustomerOfferViewer
            data={customerRewards}
            close={() => this.setState({ showRewards: false })}
            onClickCampaign={(it: any) => this.setState({ campaign: it })}
            onClickScratchCard={(it: any) => this.setState({ scratchCard: it })}
            onClickPromotion={(it: any) => this.setState({ promotion: it })}
          />
        )}
      </div>
    );
  }
}
