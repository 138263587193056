import { Component } from "react";
import css from "../style/customerLogin.module.css";
import LoginServices from "../services/login";

export default class CustomerLoginScreen extends Component<any> {
  constructor(props: any) {
    super(props);
    this.state = {
      page: "LOGIN", // OTP
      error: "",
    };
  }
  secret = "";
  phone = "";

  _onLogin = async (e: any) => {
    const loginServices = new LoginServices();
    e.preventDefault();
    this.phone = e.target.phone.value;
    await loginServices
      .generateOtp("91-" + this.phone)
      .then((res) => {
        this.secret = res.data.secret;
        this.setState({ page: "OTP" });
      })
      .catch((e) =>
        this.setState({ error: "Erron on verifying your number." })
      );
  };

  _onVerify = async (e: any) => {
    const loginServices = new LoginServices();
    e.preventDefault();
    await loginServices
      .login({
        otp: e.target.otp.value,
        secret: this.secret,
        phone: "91-" + this.phone,
      })
      .then((res) => {
        localStorage.setItem("token", res.data.token);
        window.location.pathname = "customer";
        this.setState({ page: "OTP" });
      })
      .catch((e) => this.setState({ error: "Erong otp, try again." }));
  };

  render() {
    const { page, error }: any = this.state;
    return (
      <div className={css.loginBody}>
        <div className={css.banner} />
        {page === "LOGIN" && (
          <form onSubmit={this._onLogin}>
            <div className={css.title}>Enter your mobile number to get OTP</div>
            <div className={css.desc}>
              You will receive a 4 digit code for phone number verification.
            </div>
            <div className={css.error}>{error}</div>
            <div className={css.inputArea}>
              <div className={css.countryCode}>+91</div>
              <input autoFocus placeholder="9544XXXX63" id="phone" />
            </div>
            <button>Get OTP</button>
            <center>
              By clicking, I accept the terms of service and privacy policy
            </center>
          </form>
        )}
        {page === "OTP" && (
          <form onSubmit={this._onVerify}>
            <div className={css.title}>Enter your code</div>
            <div className={css.desc}>
              Please enter the 4 digit verification code sent to +{this.phone}
            </div>
            <div className={css.error}>{error}</div>
            <input
              autoFocus
              placeholder="Enter 4 digit code"
              id="otp"
              style={{ paddingLeft: 16 }}
            />
            <button type="submit">Continue</button>
            <div className={css.textBtn}>Recent code</div>
          </form>
        )}
      </div>
    );
  }
}
