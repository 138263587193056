import { Const } from "../../globel";
import { PromotionService } from "../../services/customer";
import css from "./index.module.css";
import { Component, StrictMode, useEffect, useState } from "react";

interface OfferListPaylod {
  onSelect: any;
}

export class OffersList extends Component<OfferListPaylod> {
  constructor(props: any) {
    super(props);
    this.state = { items: [], loading: true, error: "" };
  }
  promotionService = new PromotionService();

  loaded = false;
  async componentDidMount(): Promise<void> {
    if (this.loaded) return;
    this.loaded = true;
    this._loadItems();
  }

  private async _loadItems() {
    await this.promotionService
      .getAll()
      .then((res) => this.setState({ items: res.data }))
      .catch((e) => this.setState({ error: "Error on loading offers.." }));
    this.setState({ loading: false });
  }

  render() {
    const { items, loading, error }: any = this.state;
    if (items.length === 0 || loading)
      return (
        <center style={{ color: "gray" }}>
          {loading ? "Loading.." : "Not yet earned any scratch card"}
        </center>
      );
    if (error !== "") return <center style={{ color: "red" }}>{error}</center>;
    return (
      <StrictMode>
        <div className={css.itemRow}>
          {items.map((it: any, k: number) => (
            <img
              className={css.eachPromotion}
              src={Const.imgUrl + it.image}
              onClick={() => this.props.onSelect(it)}
              key={k}
            />
          ))}
        </div>
      </StrictMode>
    );
  }
}

interface OfferPaylod {
  onClick: any;
  it: any;
}

export class Offer extends Component<OfferPaylod> {
  constructor(props: any) {
    super(props);
    this.state = {
      branches: [],
      loading: true,
      error: "",
    };
  }
  promotionService = new PromotionService();

  loaded = false;
  async componentDidMount(): Promise<void> {
    if (this.loaded) return;
    this.loaded = true;
    this._loadOffer();
  }

  private async _loadOffer() {
    const { it }: any = this.props;
    await this.promotionService
      .getOne(it.id)
      .then((res) => this.setState({ branches: res.data.branches }))
      .catch((e) => this.setState({ error: "Error on loading offers.." }));
    this.setState({ loading: false });
  }

  render() {
    const { branches, loading, error }: any = this.state;
    const { it, onClick }: any = this.props;
    return (
      <StrictMode>
        <div className={css.selected}>
          <div className={css.closeBtn} onClick={onClick} />
          <div className={css.selectedBody}>
            <div className={css.selectedhook} />
            <div style={{ overflow: "auto", paddingBottom: 80 }}>
              <img className={css.selectedImg} src={Const.imgUrl + it?.image} />
              <div
                style={{ fontSize: 26, margin: "0px 16px", fontWeight: 600 }}
              >
                Get it on
              </div>
              {branches.length === 0 ||
                (loading && (
                  <center style={{ color: "gray" }}>
                    {loading ? "Loading.." : "Not yet earned any scratch card"}
                  </center>
                ))}
              {error !== "" && (
                <center style={{ color: "red" }}>{error}</center>
              )}
              {branches.map((it1: any, k: number) => (
                <div key={k} className={css.eachBranch}>
                  <img
                    className={css.branchLogo}
                    src={Const.imgUrl + it1.picture}
                  />
                  <div style={{ width: "100%" }}>
                    <b style={{ fontSize: "18px" }}>{it1.name}</b>
                    <div style={{ color: "#5C5C5C", paddingBottom: "4px" }}>
                      {it1.address}
                    </div>
                    <div style={{ color: "#8E2CF1", fontSize: "13px" }}>
                      {it1.distance}
                    </div>
                  </div>
                  <div
                    className={css.nav}
                    onClick={() => window.open(it1.mapLink, "_blank")}
                  />
                </div>
              ))}
            </div>
          </div>
        </div>
      </StrictMode>
    );
  }
}
