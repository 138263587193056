import axios from "axios";
import { Const } from "../globel";

export default class LoginServices {
  async generateOtp(phone: string) {
    return axios.post(Const.server + "auth/customer/generateOtp", {
      phone: phone,
    });
  }

  async login(body: any) {
    return axios.post(Const.server + "auth/customer/login", body);
  }
}
