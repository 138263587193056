import css from "./index.module.css";

export function CustomerOfferViewer({
  data,
  close,
  onClickCampaign,
  onClickScratchCard,
  onClickPromotion,
}: any) {
  return (
    <div className={css.popup}>
      <div className={css.body}>
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <b>{data?.name}</b>
          <button style={{ width: 50, height: 30 }} onClick={() => close()}>
            X
          </button>
        </div>
        <div>Phone : {data?.phone}</div>
        <div>Current Points : {data?.currentPoints}</div>
        <div>Total Points : {data?.totalPoints}</div>
        <div>Tire : {data?.tire}</div>
        <div style={{ padding: "6px 0", fontWeight: "bold" }}>
          Campaigns ({data?.campaigns?.length})
        </div>
        <div style={{ display: "flex", flexWrap: "wrap" }}>
          {data?.campaigns?.map((it: any, k: number) => (
            <div
              key={k}
              onClick={() => onClickCampaign(it)}
              className={css.itemBody}
            >
              <b>{it?.header}</b>
              <div>type : {it?.type}</div>
              <div>rewardType : {it?.rewardType}</div>
              <div>discount : {it?.discount}</div>
              <div>discountItemName : {it?.discountItemName}</div>
              <div>couponCode : {it?.couponCode}</div>
              <div>redeemType : {it?.redeemType}</div>
              <div>isNeedMinPurchase : {it?.isNeedMinPurchase}</div>
              <div>minPurchase : {it?.minPurchase}</div>
              <div>isClubbingOffer : {it?.isClubbingOffer}</div>
              <div>isExchangeForCash : {it?.isExchangeForCash}</div>
            </div>
          ))}
        </div>
        <div style={{ padding: "6px 0", fontWeight: "bold" }}>
          Scratchcards ({data?.scratchCards?.length})
        </div>
        <div style={{ display: "flex", flexWrap: "wrap" }}>
          {data?.scratchCards?.map((it: any, k: number) => (
            <div
              key={k}
              onClick={() => onClickScratchCard(it)}
              className={css.itemBody}
            >
              <div>rewardType : {it?.rewardType}</div>
              <div>amount : {it?.amount}</div>
              <div>validity : {it?.validity}</div>
            </div>
          ))}
        </div>
        <div style={{ padding: "6px 0", fontWeight: "bold" }}>
          Promotions ({data?.promotions?.length})
        </div>
        <div style={{ display: "flex", flexWrap: "wrap" }}>
          {data?.promotions?.map((it: any, k: number) => (
            <div
              key={k}
              onClick={() => onClickPromotion(it)}
              className={css.itemBody}
            >
              <b>{it?.header}</b>
              <div>rewardType : {it?.rewardType}</div>
              <div>discount : {it?.discount}</div>
              <div>discountItemName : {it?.discountItemName}</div>
              <div>couponCode : {it?.couponCode}</div>
              <div>redeemType : {it?.redeemType}</div>
              <div>isNeedMinPurchase : {it?.isNeedMinPurchase}</div>
              <div>minPurchase : {it?.minPurchase}</div>
              <div>isClubbingOffer : {it?.isClubbingOffer}</div>
              <div>isExchangeForCash : {it?.isExchangeForCash}</div>
              <div>isExchangeForCash : {it?.isExchangeForCash}</div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}
