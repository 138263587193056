import { Component } from "react";

export default class PublicHomeScereen extends Component {
  render() {
    return (
      <div style={{ height: "100vh" }}>
        <div
          style={{
            display: "flex",
            justifyContent: "flex-end",
            alignItems: "flex-start",
          }}
        >
          <div
            style={{
              margin: 20,
              padding: "12px 32px",
              color: "white",
              fontWeight: "bold",
              fontSize: 16,
              cursor: "pointer",
              border: "1px solid gray",
            }}
            onClick={() => (window.location.pathname = "customer")}
          >
            Customer Login
          </div>
          
        </div>
      </div>
    );
  }
}
