import { Component } from "react";
import { ScratchCardList } from "../widget/scratchCard";

export default class CustomerScratchCardScreen extends Component {
  render() {
    return (
      <div
        style={{
          background: "linear-gradient(to bottom, #BF83FC, white,white,white)",
          height: "100%",
        }}
      >
        <div style={{ display: "flex", padding: 24 }}>
          <div></div>
          <div
            style={{
              color: "#621EB3",
              fontSize: 24,
              fontWeight: 600,
              width: "100%",
              textAlign: "center",
            }}
          >
            Scratch & Win
          </div>
        </div>
        <ScratchCardList showAll />
      </div>
    );
  }
}
