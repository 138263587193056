import axios from "axios";
import { Const, Storage } from "../globel";

export class MyServer {
  async get(url: string) {
    console.log(Const.server);
    var response: any;
    await axios
      .get(`${Const.server}${url}?location=${Storage.loaction}`, {
        headers: { Authorization: localStorage.getItem("token") },
      })
      .then((res) => (response = res))
      .catch((e) => {
        if (e.response.status == 401)
          window.location.pathname = "customer/login";
        else throw {};
      });
    return response;
  }
  async put(url: string, body: any) {
    var response: any;
    await axios
      .put(`${Const.server}${url}?location=${Storage.loaction}`, body, {
        headers: { Authorization: localStorage.getItem("token") },
      })
      .then((res) => (response = res))
      .catch((e) => {
        if (e.response.status == 401)
          window.location.pathname = "customer/login";
        else throw {};
      });
    return response;
  }
}
