import { MyServer } from "./server";

export class BusinessService extends MyServer {
  async getAbout() {
    return this.get("customer");
  }
  async getMyStores() {
    return this.get("customer/business/myStore");
  }
  async getAllBrands() {
    return this.get("customer/business/store");
  }

  async realTimeRes() {
    return this.get("customer/realTimeResponse");
  }
}

export class PromotionService extends MyServer {
  async getAll() {
    return this.get("customer/promotion");
  }
  async getOne(id: number) {
    return this.get(`customer/promotion/${id}`);
  }
}

export class ScratchCardService extends MyServer {
  async getAll() {
    return this.get("customer/scratchCard");
  }
  async scratch(id: number) {
    return this.put(`customer/scratchCard/scratch/${id}`, {});
  }
}
