import { Component } from "react";
import { Const } from "../../globel";
import css from "./index.module.css";
import { ScratchCardService } from "../../services/customer";

class ScratchCardListPayload {
  showAll?: boolean = false;
}

function ScratchCard({ it }: any) {
  return (
    <div
      className={css.eachScratchCard}
      style={{
        backgroundColor: it.scratched ? it.bgColor : it.outerColor,
        backgroundImage: !it.scratched
          ? `url(${Const.imgUrl}scratchCard/${it.outerPattern})`
          : "",
      }}
      onDoubleClick={() => {}}
    >
      {!it.scratched ? (
        <img src={`${Const.imgUrl}scratchCard/${it.outerIcon}`} width="60%" />
      ) : !it.won ? (
        <div style={{ fontWeight: "600", textAlign: "center" }}>
          Better luck!
          <br /> next time.
        </div>
      ) : (
        <div style={{ height: "100%" }}>
          <img
            src={`${Const.imgUrl}${it.coverImg}`}
            width="100%"
            height="45%"
            style={{ borderRadius: 18, objectFit: "cover" }}
          />
          <img
            src={`${Const.imgUrl}${it.businessLogo}`}
            style={{
              width: "56px",
              height: "56px",
              borderRadius: "10px",
              marginTop: "-28px",
              marginLeft: "20px",
              objectFit: "cover",
            }}
          />
          <div
            style={{
              fontWeight: "700",
              fontSize: "28px",
              padding: "0 14px",
              color: it.textColor,
            }}
          >
            {it.currencySymbol}
            {it.amount}
          </div>
          <div
            style={{
              fontSize: "10.5px",
              padding: "4px 14px",
              fontWeight: "600",
              color: it.textColor,
            }}
          >
            You have won {it.amount} {it.currencyCode} discount on next purchase
          </div>
        </div>
      )}
    </div>
  );
}

export class ScratchCardList extends Component<ScratchCardListPayload> {
  constructor(props: ScratchCardListPayload) {
    super(props);
    this.state = {
      items: [],
      loading: true,
      error: "",
      scratched: false,
    };
  }
  scratchCardService = new ScratchCardService();

  loaded = false;
  async componentDidMount(): Promise<void> {
    if (this.loaded) return;
    this.loaded = true;
    this._loadScratchCards();
  }

  private async _loadScratchCards() {
    await this.scratchCardService
      .getAll()
      .then((res) => this.setState({ items: res.data }))
      .catch((e) =>
        this.setState({ error: "Error on loading scratch cards.." })
      );
    this.setState({ loading: false });
  }

  private async _scratchCard() {
    const { selected }: any = this.state;
    await this.scratchCardService
      .scratch(selected.id)
      .then((res) => this._loadScratchCards())
      .catch((e) => this.setState({}));
  }

  render() {
    const { items, loading, error, selected }: any = this.state;
    const { showAll }: ScratchCardListPayload = this.props;
    if (items.length === 0 || loading)
      return (
        <center style={{ color: "gray" }}>
          {loading ? "Loading.." : "Not yet earned any scratch card"}
        </center>
      );
    if (error !== "") return <center style={{ color: "red" }}>{error}</center>;
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
        }}
      >
        <div className={showAll ? css.itemWrap : css.itemRow}>
          {items.map((it: any, k: any) => (
            <div
              onClick={() => {
                if (!it.scratched) this.setState({ selected: it });
              }}
              key={k}
            >
              <ScratchCard it={it} />
            </div>
          ))}
          <div style={{ width: 182 }} />
        </div>

        {selected && (
          <div className={css.selected}>
            <div onClick={() => this._scratchCard()}>
              <ScratchCard it={selected} />
            </div>
          </div>
        )}
      </div>
    );
  }
}
